/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import AOS from 'aos';

// You can delete this file if you're not using it
import './src/scss/main.scss'
import 'slick-carousel/slick/slick.css'
import 'aos/dist/aos.css'


// Animate on scroll
export function onInitialClientRender( ) {
	AOS.init({
		duration: 800,
	});
}